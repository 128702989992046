import { ROLES } from '../constants'


export const getCurrentUser = (profile: any) => {
    const userMetaData = profile['https://user_metadata']
    const isMondraUser = profile['https://mondra.com/IsMondraUser']
    return {
        accountEnabled: true,
        allowNotification: true,
        displayName: profile?.given_name
            ? `${profile?.given_name} ${profile?.family_name}`
            : `${userMetaData.firstName} ${userMetaData.lastName}`,
        email: profile?.email,
        givenName: profile?.given_name || userMetaData.firstName,
        id: profile?.id,
        isMondraUser,
        photo: profile?.photo,
        role: isMondraUser ? ROLES.MondraAdmin : ROLES.User,
        surname: profile?.family_name || userMetaData.lastName,
    }
}
